<template>
  <div class="search-location">
    <label
      v-if="showLabel"
      for="search-location"
    >{{ label || t('location.title') }}</label>
    <input
      id="search-location"
      ref="placeEl"
      v-model="search"
      data-testid="search-input"
      autocomplete="off"
      type="text"
      :placeholder="t('location.placeholder.where')"
      @blur="inputBlur"
      @focus="showPredictions"
      @keyup.enter="keyUpEnter"
      @compositionupdate="search = $event.data"
    >
    <ZSpinner
      v-if="searchPredictionsLoading"
      size="sm"
    />
    <FormInputClearButton
      v-else-if="search && $search.parameters.location?.fullName"
      :variant="placeIsFocused ? 'light' : 'dark'"
      @click="clearPlace"
    />
    <LazySearchLocationPredictions
      v-if="isPredictionsShown"
      ref="predictionsEl"
      :predictions="searchPredictions"
      @set-place="setPlace"
    />
  </div>
</template>

<script setup>
const { t } = useI18n({
  useScope: 'local',
})

const props = defineProps({
  location: {
    type: Object,
    default: null,
  },

  label: {
    type: String,
    default: '',
  },

  showLabel: {
    type: Boolean,
    default: false,
  },

  isVisbible: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['update', 'clear', 'blur'])

const placeEl = ref(null)
const search = ref(props.location?.fullName ?? '')
const predictionsVisible = ref(false)
const searchPredictions = ref([])
const searchPredictionsLoading = ref(false)
const isSettingPlace = ref(false)
const placeIsFocused = ref(false)

const isPredictionsShown = computed(() => predictionsVisible.value && searchPredictions.value.length > 0)

watch(
  () => props.isVisbible,
  (newVal) => {
    if (newVal) {
      setTimeout(() => {
        placeEl.value?.focus()
      }, 200)
    }
  },
  { immediate: true },
)

watch(search, (newVal) => {
  predictionsVisible.value = false
  if (newVal && !isSettingPlace.value) {
    getPlacesPredictions(newVal)
  }
})

const { $search } = useNuxtApp()
watch(
  () => $search.parameters.location.fullName,
  (newVal) => {
    isSettingPlace.value = true
    search.value = newVal
    if (!newVal) {
      searchPredictions.value = []
    }
    isSettingPlace.value = false
  },
)

function inputBlur() {
  // Clear value on desktop
  if (!isPredictionsShown.value) {
    emit('blur')
  }
}

function showPredictions() {
  if (search.value && searchPredictions.value.length === 0) {
    getPlacesPredictions(search.value)
  }
  else {
    predictionsVisible.value = true
  }

  placeIsFocused.value = true
}

let searchTimer = null
const runtimeConfig = useRuntimeConfig()
const { isMobileOrTablet } = useDevice()

function getPlacesPredictions(searchText) {
  search.value = searchText
  searchPredictions.value = []
  searchPredictionsLoading.value = false
  predictionsVisible.value = false

  if (!searchText) {
    return
  }

  searchPredictionsLoading.value = true

  clearTimeout(searchTimer)
  searchTimer = setTimeout(
    async () => {
      const { placePredictions } = await useGoogleMaps()
      const predictions = await placePredictions(searchText, runtimeConfig.public.supportedCountries)
      searchPredictions.value = predictions
      searchPredictionsLoading.value = false
      predictionsVisible.value = Boolean(search.value)
    },
    isMobileOrTablet ? 1000 : 500,
  )
}

function setPlace(place) {
  isSettingPlace.value = true
  placeIsFocused.value = false

  search.value = place.description

  emit('update', place)

  nextTick(() => {
    predictionsVisible.value = false
    isSettingPlace.value = false
  })
}

function clearPlace() {
  search.value = ''
  searchPredictions.value = []
  emit('clear')
}

function keyUpEnter() {
  if (isPredictionsShown.value) {
    setPlace(searchPredictions.value[0])
  }
}

/**
 * Clicking outside
 */
const predictionsEl = ref(null)
onClickOutside(
  predictionsEl,
  () => {
    predictionsVisible.value = false
    placeIsFocused.value = false

    emit('blur')
  },
  { ignore: [placeEl] },
)
</script>

<i18n src="~/locales/common/search/form/location.json" lang="json" />

<i18n src="~/locales/common/location.json" lang="json" />
